.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mt-50 {
  margin-top: 50px;
}

.half-screen {
  width: 35%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  img {
    width: 60%;
  }
  .half-screen {
    width: 80%;
    margin: auto;
  }
}
