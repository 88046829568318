/* Dashboard Sidebar Styling */
.dashboard-sidebar {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: #faf7f0;
}

.dashboard-sidebar-logo {
  max-width: 250px;
  height: auto;
  margin: auto;
}

.dashboard-sidebar-logo img {
  margin: 20px auto;
  width: 80%;
}
