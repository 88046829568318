.resources__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

/* Receipts Styling */
.receipts-modal__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .receipts-modal__bottom {
    padding: 0.25rem;
  }
}

.card-content {
  border: 1px solid #000;
  border-radius: 5px;
  max-width: 250px;
}

.preText {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
