.customer-details {
  margin: 10px;
  padding: 10px;
}

.customer-details__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.customer-details__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}

.customer-details__grid__item {
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .customer-details {
    margin: 5px;
    padding: 0;
  }

  .customer-details__grid {
    grid-template-columns: 1fr 1fr;
  }
}
