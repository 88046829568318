/* Auth Layout Styles */
.auth-layout-logo {
  max-width: 400px;
  height: auto;
  margin: auto;
}

.auth-layout-logo img {
  margin: 20px auto;
  width: 80%;
}
