.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-space-between {
  align-items: center;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.p {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 5rem;
}

.py {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.m {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.m3 {
  margin: 3rem;
}

.m4 {
  margin: 4rem;
}

.m5 {
  margin: 5rem;
}

.my {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.text-center {
  text-align: center;
}

/* Modal style */

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 50%;
  margin: 100px auto;
  overflow-y: hidden;
  background-color: #fff;
  box-shadow: 24px;
  border-radius: 5px;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .modal-style {
    min-width: 80%;
  }
}
