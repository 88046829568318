/* styles.css */
.customLink {
  color: color(srgb red green blue);
  text-decoration: none;
}
.customLink:hover {
  color: red;
  text-decoration: underline;
}
.container {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  padding: 20px;
}
.chatContainer {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 80vh;
  max-height: 85vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chatMessages {
  margin-bottom: 10px;
}

.message {
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 80%;
}

.userMessage {
  background-color: #007bff;
  color: #fff;
  align-self: flex-end;
}

.botMessage {
  background-color: #f0f0f0;
  color: #333;
  align-self: flex-start;
}

.chatInput {
  display: flex;
}

.inputField {
  flex: 1;
  padding: 10px;
  border-radius: 5px 0 0 5px;
}

.sendButton {
  padding: 10px 15px;
}

.sound-wave {
  width: 100%;
  height: auto;
}

audio {
  background-color: #f5f5f5;
  border-radius: 10px;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-pause-button {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .chatContainer {
    width: 100%;
  }

  .container {
    flex-direction: column;
  }

  .sound-wave {
    width: 70vw;
  }
}
